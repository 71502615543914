export default class RTEContent {
	constructor(config) {
		this.el = config.el;

		this.init();
	}
	init() {
		this.addAriaHiddenToImages();
	}
	addAriaHiddenToImages() {
		if (!this.el) return;

		const images = this.el.querySelectorAll('img');
		images.forEach((img) => {
		  if (!img.hasAttribute('aria-hidden')) {
			img.setAttribute('aria-hidden', 'true');
		  }
		});
	  }
}
